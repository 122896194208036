import {
	computed, makeObservable, observable, action,
} from 'mobx';

import { IAddToCartProduct, IAddToCartProductJSON } from '~/product/common/Interfaces/IAddToCart.tracking';

import { ifEmptyUsePlaceholderText, LAUNCH_PLACEHOLDER_TEXT } from '~/util/launchUtils';
import { sanitize } from '~/util/sanitizeString';

export class TrackedProduct implements IAddToCartProduct {
	productAnalyticsData: any;

	productVendorData: any;

	constructor(productAnalyticsData: any = {}, productVendorData: any = {}) {
		this.productAnalyticsData = productAnalyticsData;
		this.productVendorData = productVendorData;

		makeObservable(this, {
			id: computed,
			name: computed,
			productAnalyticsData: observable.ref,
			quantity: computed,
			thumb: computed,
			thumb2: computed,
			url: computed,
			url2: computed,
			unitPrice: computed,
			webCat: computed,
			webICP: computed,
			webSub: computed,
			webMessage: computed,
			imagePathMinusBaseUrl: computed,
			stockedStatus: computed,
			equalTo: action.bound,
		});
	}

	get id(): string {
		return this.productAnalyticsData.articleNumber;
	}

	get cartNumber(): string {
		return this.productAnalyticsData.cartNumber;
	}

	get name(): string {
		return (this.productAnalyticsData.name && sanitize(this.productAnalyticsData.name)) || LAUNCH_PLACEHOLDER_TEXT;
	}

	get imagePathMinusBaseUrl(): string {
		const imageUrl = this.productAnalyticsData.imageUrl || '';
		return imageUrl.replace('https://rnb.scene7.com/is/image/roomandboard/', '');
	}

	get insert(): string {
		return (this.productAnalyticsData.insert && sanitize(this.productAnalyticsData.insert)) || LAUNCH_PLACEHOLDER_TEXT;
	}

	get thumb(): string {
		return ifEmptyUsePlaceholderText(this.imagePathMinusBaseUrl.substring(0, 255));
	}

	get thumb2(): string {
		return ifEmptyUsePlaceholderText(this.imagePathMinusBaseUrl.substring(255, 255));
	}

	get urlString(): string {
		return this.productAnalyticsData.productUrl || LAUNCH_PLACEHOLDER_TEXT;
	}

	get url(): string {
		return ifEmptyUsePlaceholderText(this.urlString.substring(0, 255));
	}

	get url2(): string {
		return ifEmptyUsePlaceholderText(this.urlString.substring(255, 255));
	}

	get webCat(): string {
		const {
			urlCategory,
			category = urlCategory,
		} = this.productAnalyticsData;
		return ifEmptyUsePlaceholderText(category);
	}

	get webICP(): string {
		const {
			urlICP,
			icp = urlICP,
		} = this.productAnalyticsData;
		return ifEmptyUsePlaceholderText(icp);
	}

	get webSub(): string {
		const {
			urlSubcategory,
			subcategory = urlSubcategory,
		} = this.productAnalyticsData;
		return ifEmptyUsePlaceholderText(subcategory);
	}

	get quantity(): number {
		return this.productAnalyticsData.quantity;
	}

	get unitPrice(): number {
		return this.productAnalyticsData.unitPrice;
	}

	get formattedUnitPrice(): string {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(this.unitPrice).replace(/\$|,/g, '');
	}

	get productGroupKey(): string {
		return this.productAnalyticsData.productGroupKey;
	}

	get stockedStatus(): string {
		return this.productAnalyticsData.stockedStatus;
	}

	get webMessage(): string {
		const {
			productStatus,
			webMessage = productStatus,
		} = this.productAnalyticsData;
		return ifEmptyUsePlaceholderText(webMessage);
	}

	get reflektionSkuKeys(): Array<string> {
		const rfkSkuKeys: Array<string> = [];

		if (!this.productVendorData.reflektion) {
			return rfkSkuKeys;
		}

		this.productVendorData.reflektion.forEach((rfk: { skuKey: string; }) => {
			rfkSkuKeys.push(rfk.skuKey);
		});

		return rfkSkuKeys;
	}

	get variantId(): string {
		const { variantIds = [] } = this.productVendorData;

		const [variantId = ''] = variantIds;

		return variantId;
	}

	get unattachedOttoman(): boolean {
		return this.productAnalyticsData.unattachedOttoman;
	}

	get trackingJson(): IAddToCartProductJSON {
		return {
			id: this.id,
			stockedStatus: this.stockedStatus,
			quantity: this.quantity,
			uPrice: this.formattedUnitPrice,
			name: this.name,
			thumb: this.thumb,
			thumb2: this.thumb2,
			url: this.url,
			url2: this.url2,
			webCat: this.webCat,
			webICP: this.webICP,
			webSub: this.webSub,
			webMessage: this.webMessage,
			reflektionSkuKeys: this.reflektionSkuKeys,
			variantId: this.variantId,
			productGroupKey: this.productGroupKey,
			insert: this.insert,
			...(this.unattachedOttoman && { ottomanAttachment: 'unattached' }),
		};
	}

	get comparatorString(): string {
		return JSON.stringify(this.trackingJson);
	}

	equalTo(comparatorString: string): boolean {
		return this.comparatorString === comparatorString;
	}
}
