import React from 'react';
import { observable } from 'mobx';
import axios from 'axios';

import { isMobile } from '~/global/global.constants';
import { redirect } from '~/util/redirect';
import { autoFocusHelper } from '~/util/autoFocusHelper';
import { encodePassword } from '~/util/encodePassword';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { isFunction } from '~/util/isFunction';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';
import { AccountAccessEventsTrackingStoreFactory } from '~/tracking/account/access-events/Stores/AccountAccessEvents.tracking.store';
import { SiteMessagingStore } from '~/global/site-messaging/SiteMessaging.store';
import { OneTrustStore } from '~/account/sign-in/Stores/OneTrust.store';

export const formModel = (defaults = {}) => (
	observable({
		// username: `test${Date.now()}@roomandboard.com`,
		// password: 'password',
		// zipCode: '55445' || defaults.zipCode,
		username: defaults.username || '',
		password: '',
		zipCode: defaults.zipCode || '',
		showPassword: false,
		subscribe: false,
	})
);

export const formSettings = (store, globalDynamicStore, HREF) => {
	const globalDynamicModel = globalDynamicStore.model;
	const oneTrustStore = new OneTrustStore({});

	return {
		id: 'createAccount',
		reactProps: {
			key: 'create-account-form',
			method: 'POST',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin(
					{
						ajaxSubmit: {
							submitHandler: (form) => {
								if (form.plugins.formValidator.hasErrors) {
									// Scroll up to make sure error is visible
									window.scrollTo(0, 0);
									return false;
								}
								store.hasRequestError = false;
								if (!store.useConvertGuest && !globalDynamicModel.createAccountLink) {
									return false;
								}
								if (store.useConvertGuest && !store.convertGuestLink) {
									return false;
								}
								const { username } = form.model;
								const password = encodePassword(form.model.password);

								store.isSubmitProcessing = true;

								if (store.useConvertGuest) {
									return axios.post(store.convertGuestLink, {
										username,
										password,
									}).then(() => {
										globalDynamicStore.fetchData();
									});
								}
								return axios.post(globalDynamicModel.createAccountLink, {
									username,
									password,
									zipCode: form.model.zipCode,
									agreedToEmailUpdates: form.model.subscribe,
								});
							},
							promiseHandler: async (promise, form) => {
								try {
									await promise;
									await globalDynamicStore.fetchData();
									store.isSubmitProcessing = false;
									store.isSubmitted = true;

									const {
										customerTrackingKey = null,
										gpcSignal = false,
									} = globalDynamicModel;

									oneTrustStore.handleGPCSignal(gpcSignal);

									const featureTogglesModel = new FeatureTogglesModel(globalDynamicModel);
									const accountEventsTrackingStore = AccountAccessEventsTrackingStoreFactory.create(featureTogglesModel);
									const siteMessaging = new SiteMessagingStore({});

									accountEventsTrackingStore.trackSignInEvent(customerTrackingKey);
									siteMessaging.globalDynamic = globalDynamicModel;

									if (isFunction(store.callback)) {
										store.callback();
									} else if (siteMessaging.hasOutageMessage) {
										redirect('/');
									} else {
										redirect(HREF.account.overview);
									}
								} catch (error) {
									let errors;
									let mappedErrors;

									if (error?.response?.status === '500') {
										errors = [{
											errorKey: 'username',
											errorMessage: 'invalidEmail',
											fieldError: true,
										}];
										mappedErrors = form.plugins.formValidator.mapErrorsToFieldErrors(errors);
										form.plugins.formValidator.mapServerSideValidationMessages(mappedErrors);
									} else if (error === 'T_ZONE_NOT_FOUND') {
										errors = [{
											errorKey: 'zipCode',
											errorMessage: 'transportationZoneForbidden',
											fieldError: true,
										}];
										mappedErrors = form.plugins.formValidator.mapErrorsToFieldErrors(errors);
										form.plugins.formValidator.mapServerSideValidationMessages(mappedErrors);
									} else if (error?.response?.data?.errors[0]?.errorKey === 'invalidEmailError' || error?.response?.data?.errors[0]?.errorKey === 'salesforceInvalidEmail') {
										errors = [{
											errorKey: 'username',
											errorMessage: 'salesforceInvalidEmail',
											fieldError: true,
										}];
										mappedErrors = form.plugins.formValidator.mapErrorsToFieldErrors(errors);
										form.plugins.formValidator.mapServerSideValidationMessages(mappedErrors);
									} else if (error?.response?.data?.errors[0]?.errorKey === 'accountExistsError') {
										errors = [{
											errorKey: 'username',
											errorMessage: 'usernameUniqueConstraintError',
											fieldError: true,
										}];
										mappedErrors = form.plugins.formValidator.mapErrorsToFieldErrors(errors);
										form.plugins.formValidator.mapServerSideValidationMessages(mappedErrors);
									}

									// set focus on the first error control element
									if (error?.response?.data?.errors?.length) {
										const firstErrorKey = error.response.data.errors[0].errorKey;
										store.form?.fields?.[firstErrorKey]?.control?.controlRef?.focus?.();
									}

									store.submitted = false;
									store.isSubmitProcessing = false;
									store.isSubmitted = false;

									// Scroll up to make sure error is visible
									window.scrollTo(0, 0);
									return error;
								}
								return promise;
							},
						},
					},
				),
			],
		},
		fields: {
			username: {
				control: {
					reactProps: {
						type: 'email',
						noValidate: true,
						size: 22,
						required: true,
						autoComplete: 'email',
						ref: (component) => {
							if (!isMobile) {
								autoFocusHelper(component, 100);
							}
						},
					},
				},
				label: {
					reactProps: {
						children: 'Email Address',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your email address',
						},
						email: {
							message: '^Please enter a valid email address.',
						},
					},
				},
			},
			password: {
				control: {
					reactProps: {
						autoCapitalize: 'false',
						autoCorrect: 'false',
						autoComplete: 'new-password',
						spellCheck: false,
						noValidate: true,
						required: true,
						size: 22,
					},
				},
				label: {
					reactProps: {
						children: 'Password',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your password',
						},
						length: {
							minimum: 6,
							maximum: 256,
						},
						// printableAscii: true,
					},
				},
			},
			showPassword: {
				reactProps: {
					className: 'inline-group show-password',
					noValidate: true,
				},
				settings: {
					validationConstraints: {},
				},
			},
			zipCode: {
				control: {
					reactProps: {
						type: 'text',
						autoComplete: 'postal-code',
						maxLength: 10,
						size: 10,
						noValidate: true,
						required: true,
					},
				},
				label: {
					reactProps: {
						children: [
							React.createElement('span', {
								className: 'tw-uppercase',
							}, 'Zip'),
							' Code',
						],
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your zip code',
						},
						zipCode: true,
						length: {
							minimum: 5,
							maximum: 10,
						},
						// printableAscii: true,
					},
				},
			},
			subscribe: {
				control: {
					reactProps: {
						type: 'checkbox',
						noValidate: true,
					},
				},
				label: {
					reactProps: {
						children: [
							'Please send me email updates about products and special events. We will not share, sell or trade your email. ',
							<a rel="noreferrer noopener" key="privacy-policy" href="/privacy/" target="_blank">Privacy Policy</a>,
						],
					},
				},
				settings: {
					validationConstraints: {},
				},
			},
		},
	};
};
