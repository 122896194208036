'use client';

// vercel suggests using useEffect for client-only content https://github.com/vercel/next.js/discussions/17443

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

interface Props {
	children: React.ReactNode
}
export const ClientOnly = observer(({ children }: Props) => {
	const [isOnClient, setIsOnClient] = useState(false);
	useEffect(() => {
		setIsOnClient(true);
	}, []);
	return isOnClient ? <>{children}</> : null;
});
