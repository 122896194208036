'use client';

import React, { useEffect } from 'react';
import Script from 'next/script';

import { observer } from 'mobx-react';

import { reflektionPath } from '~/global/global.constants';

export const ReflektionScripts = observer(() => {
	if (!reflektionPath) {
		return null;
	}

	// re-init rfk search widgets after SPA navigation events
	// the input element gets re-rendered and the persistent library loses track of it
	useEffect(() => {
		const tsWindow = window as any;

		if (tsWindow.rfk && tsWindow.rfk.init) {
			tsWindow.rfk.init();
		}
	}, []);

	return (
		<>
			<Script
				strategy="afterInteractive"
				src={reflektionPath}
			/>
		</>
	);
});
