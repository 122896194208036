'use client';

import React from 'react';
import Script from 'next/script';

import { observer } from 'mobx-react';

import { humanSensorEnabled, humanSensorScriptUrl } from '~/global/global.constants';

export const HumanSensor = observer(() => {
	if (!humanSensorEnabled) {
		return null;
	}

	return (
		<>
			<Script
				strategy="afterInteractive"
				src={humanSensorScriptUrl}
			/>
		</>
	);
});
