'use client';

import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { CreateAccountForm } from '~/account/create/Components/CreateAccountForm';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { AsyncFormSubmitLoading } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmitLoading';
import { s7ImagePath } from '~/global/global.constants';

import styles from '#/account/sign-in/sign-in.module.scss';

export const CreateAccountPage = observer((props) => {
	const {
		store: {
			isSubmitted,
			isSubmitProcessing,
			form,
		} = {},
	} = props;

	if (isSubmitted || isSubmitProcessing) {
		return (
			<div className={styles['spinner-container']}>
				<LoadingSpinner isLoading parentSettings={{ width: '100%', height: '500px' }} />
			</div>
		);
	}

	return (
		<div className="ajax-submit-wrapper create-account tw-relative">
			<AsyncFormSubmitLoading form={form} />

			<div className="tw-px-3 tw-py-6 md:tw-grid tw-gap-4 tw-grid-cols-2 md:tw-px-6">
				<div className="form-section">
					<h1>
						Create an Account
					</h1>
					<CreateAccountForm {...props} />
				</div>

				<div className={classNames(styles['info-section'], 'info-section tw-pt-12')}>
					<h2 className={classNames('medium', styles['form-intro'])}>
						A Room & Board account lets you:
					</h2>
					<ul style={{ listStyleImage: `url(${s7ImagePath}/biPage21_checkmark?wid=18)` }}>
						<li>Check out faster</li>
						<li>Save to Favorites</li>
						<li>Track current and past orders</li>
						<li>Schedule delivery</li>
						<li>Make a payment</li>
					</ul>
				</div>
			</div>
		</div>
	);
});
