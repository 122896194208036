import React from 'react';
import classNames from 'classnames';

export type headerLevelType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 1 | 2 | 3 | 4 | 5 | 6

interface headerLibraryProps {
	className?: string,
	children?: JSX.Element | string | number,
	props?: any,
}

interface magicHeaderTagProps {
	children: JSX.Element | string | number,
	// optional
	headerLevel?: headerLevelType,
	headerClassLevel?: headerLevelType,
	className?: string,
	props?: any,
}

const headerClassLevelLibrary = {
	'1': 'Heading Heading--1',
	'2': 'Heading Heading--2',
	'3': 'Heading Heading--3',
	'4': 'Heading Heading--4',
	'5': 'Heading Heading--5',
	'6': 'Heading Heading--6',
	'h1': 'Heading Heading--1',
	'h2': 'Heading Heading--2',
	'h3': 'Heading Heading--3',
	'h4': 'Heading Heading--4',
	'h5': 'Heading Heading--5',
	'h6': 'Heading Heading--6',
};

const headerTagLibrary = {
	'1': ({ className, children, props }: headerLibraryProps) => <h1 className={className} {...props}>{children}</h1>,
	'2': ({ className, children, props }: headerLibraryProps) => <h2 className={className} {...props}>{children}</h2>,
	'3': ({ className, children, props }: headerLibraryProps) => <h3 className={className} {...props}>{children}</h3>,
	'4': ({ className, children, props }: headerLibraryProps) => <h4 className={className} {...props}>{children}</h4>,
	'5': ({ className, children, props }: headerLibraryProps) => <h5 className={className} {...props}>{children}</h5>,
	'6': ({ className, children, props }: headerLibraryProps) => <h6 className={className} {...props}>{children}</h6>,
	'h1': ({ className, children, props }: headerLibraryProps) => <h1 className={className} {...props}>{children}</h1>,
	'h2': ({ className, children, props }: headerLibraryProps) => <h2 className={className} {...props}>{children}</h2>,
	'h3': ({ className, children, props }: headerLibraryProps) => <h3 className={className} {...props}>{children}</h3>,
	'h4': ({ className, children, props }: headerLibraryProps) => <h4 className={className} {...props}>{children}</h4>,
	'h5': ({ className, children, props }: headerLibraryProps) => <h5 className={className} {...props}>{children}</h5>,
	'h6': ({ className, children, props }: headerLibraryProps) => <h6 className={className} {...props}>{children}</h6>,
};

// NOTES: use for instances when header level must change (between renders, component instances, etc...)
export const MagicHeaderTag = ({
	headerLevel,
	headerClassLevel,
	className,
	children,
	props,
}: magicHeaderTagProps) => {
	const classLevel = headerClassLevel && headerClassLevelLibrary[headerClassLevel];
	const header = headerLevel && headerTagLibrary[headerLevel];
	return header
		? header({ className: classNames(classLevel, className), children, props })
		: <div className={classNames(classLevel, className)} {...props}>{children}</div>;
};
