'use client';

import React, { useEffect, useRef } from 'react';
import lozad from 'lozad';

export const LazyPicture = (props) => {
	const {
		alt = '',
		className = '',
		mediumSrcSet = '',
		mediumSrcDimensions = [],
		smallSrcDimensions = [],
		smallSrcSet = '',
		fallbackSrc = '',
		triggerLoad,
	} = props;

	const pictureRef = useRef();

	let lozadObserver = null;

	const [smallSrcWidth = null, smallSrcHeight = null] = smallSrcDimensions;

	const [mediumSrcWidth = null, mediumSrcHeight = null] = mediumSrcDimensions;

	useEffect(() => {
		lozadObserver = lozad(pictureRef.current);
		lozadObserver.observe();
		if (triggerLoad && pictureRef.current) {
			lozadObserver.triggerLoad(pictureRef.current);
		}
	}, [pictureRef]);

	return (
		<picture className={`${className} lozad lazy-picture`} ref={pictureRef} data-iesrc={fallbackSrc} data-alt={alt}>
			<source
				className={className}
				srcSet={smallSrcSet}
				media="(max-width: 640px)"
				width={smallSrcWidth}
				height={smallSrcHeight}
			/>
			<source
				className={className}
				srcSet={mediumSrcSet}
				media="(min-width: 641px)"
				width={mediumSrcWidth}
				height={mediumSrcHeight}
			/>
			<img className={className} alt={alt} src={fallbackSrc} />
		</picture>
	);
};
