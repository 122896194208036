'use client';

import { useEffect, useTransition } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { noop } from '~/util/noop';
import { scrollToTop } from '~/util/scrollToTop';

export const useGlobalResets = () => {
	const {
		headerMobileStore: {
			setIsAccountOpen = noop,
			setIsSearchOpen = noop,
		} = {},
		magicModal,
	} = useGlobalContext();

	const [isPending] = useTransition();

	return {
		autoCloseHeaderMobileAccountMenu: () => {
			const autoCloseHeaderMobileAccountMenu = () => {
				setIsAccountOpen(false);
			};

			useEffect(() => {
				if (isPending) {
					autoCloseHeaderMobileAccountMenu();
				}
			}, [isPending]);
		},
		autoCloseHeaderMobileSearch: () => {
			const autoCloseHeaderMobileSearch = () => {
				setIsSearchOpen(false);
			};
			useEffect(() => {
				if (isPending) {
					autoCloseHeaderMobileSearch();
				}
			}, [isPending]);
		},
		autoCloseModal: () => {
			useEffect(() => {
				if (isPending) {
					magicModal.model.onCloseModal = null;
					magicModal.closeModal();
				}
			}, [isPending]);
		},
		autoScrollToTop: () => {
			let timeoutId = -1;
			const scrollToTopTimeout = () => {
				timeoutId = window.setTimeout(scrollToTop, 50);
			};
			useEffect(() => {
				if (isPending) {
					scrollToTopTimeout();
				} else {
					window.clearTimeout(timeoutId);
				}
			}, [isPending]);
		},
	};
};
