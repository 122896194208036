import React, { useEffect, useRef } from 'react';
import lozad from 'lozad';

import { addToUrl } from '~/util/addToUrl';

export const LazyImage = (props) => {
	const {
		alt = '',
		src = '',
		srcSet = '',
		sizes = '',
		placeholderQuality = 1,
		width = null,
		height = null,
	} = props;

	const lazyImageRef = useRef(null);

	const srcPlaceholder = addToUrl(src, `qlt=${placeholderQuality}`);

	const sanitizedSrcSet = srcSet.replace(/\s+/g, ' ').trim();

	const srcSetParts = sanitizedSrcSet.split(', ');

	const srcSetPlaceholderParts = srcSetParts.map((srcSetPart = '') => {
		const srcSetPartParts = srcSetPart.split(' ');

		const srcSetRoot = srcSetPartParts[0];

		const srcSetSize = srcSetPartParts[1] ? ` ${srcSetPartParts[1]}` : '';

		if (srcSetRoot) {
			return addToUrl(srcSetRoot, `qlt=${placeholderQuality}${srcSetSize}`);
		}

		return '';
	});

	const srcSetPlaceholder = srcSetPlaceholderParts.join(', ');

	useEffect(() => {
		if (lazyImageRef.current) {
			lozad(lazyImageRef.current).observe();
		}
	}, []);

	return (
		<img
			alt={alt}
			data-src={src}
			data-srcset={srcSet}
			ref={lazyImageRef}
			sizes={sizes}
			src={srcPlaceholder}
			srcSet={srcSetPlaceholder}
			width={width}
			height={height}
		/>
	);
};
