import classNames from 'classnames';
import React, { forwardRef } from 'react';
import type { ForwardedRef } from 'react';

import { ArrowButtonProps, ButtonProps, ButtonVariant } from '~/components/Buttons/Types/constants';
import { buildButtonClassNames } from '~/components/Buttons/Utils/Button.utils';
import { ButtonBase } from '~/components/Buttons/Components/ButtonBase';

export const Button = forwardRef(
	<V extends ButtonVariant>(
		props: ButtonProps<V> & { children?: React.ReactNode },
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const {
			variant = ButtonVariant.Primary, className: propsClassName = '', color, isTransparent, ...restProps
		} = props;

		let direction;
		let size;
		let className = buildButtonClassNames(variant, color, isTransparent);

		if (variant === ButtonVariant.Arrow) {
			({ direction, size } = restProps as unknown as ArrowButtonProps);
			className = buildButtonClassNames(variant, color, isTransparent, direction, size);
			if (!direction || !size) {
				throw new Error('Arrow buttons must have direction and size props.');
			}
		}

		return (
			<ButtonBase
				{...restProps}
				className={
					classNames(className, {
						[propsClassName]: propsClassName,
					})
				}
				ref={ref}
			>
				{props.children}
			</ButtonBase>
		);
	}
);
