import React, { ComponentProps } from 'react';
import classNames from 'classnames';

import { TextLinkColor } from '../Types/constants';
import { buildTextLinkClassNames } from '../Utils/Button.utils';

import { ButtonBase } from './ButtonBase';

export interface IProps extends Omit<ComponentProps<typeof ButtonBase>, 'color'> {
	color?: TextLinkColor;
	hasNoUnderline?: boolean;
	leftAlign?: boolean;
}

export const TextButton = (props: IProps) => {
	const {
		color, className: propsClassName = '', hasNoUnderline = false, leftAlign = false, ...buttonProps
	} = props;
	const className = buildTextLinkClassNames(color, hasNoUnderline, leftAlign);

	return (
		<ButtonBase
			{...buttonProps}
			className={
				classNames(className, {
					[propsClassName]: propsClassName,
				})
			}
		>
			{props.children}
		</ButtonBase>
	);
};
