import {
	makeObservable, observable, action,
} from 'mobx';

import { IAddToCartEventJSON, IAddToWishlistEventJSON } from '~/product/common/Interfaces/IAddToCart.tracking';
import { TrackedCart } from '~/product/common/Models/TrackedCart.tracking.model';
import { TrackedProduct } from '~/product/common/Models/TrackedProduct.tracking';

export class AddProductToCartTrackingBase {
	addToCartData: any

	cart: TrackedCart

	checkForBackToBackDuplicates: boolean

	eventName: string

	globalDynamicModel: any

	products: Array<TrackedProduct>

	reflektion: Array<any>

	variantIds: Array<string>

	wasSuccessful: boolean

	constructor(addToCartData: any = {}, products: Array<TrackedProduct> = [], cart: TrackedCart, reflektion: Array<any>, variantIds: Array<string> = [], eventName: string, wasSuccessful: boolean) {
		makeObservable(this, {
			addToCartData: observable.ref,
			equalTo: action.bound,
		});
		this.addToCartData = addToCartData;
		this.cart = cart;
		this.reflektion = reflektion;
		this.variantIds = variantIds;
		this.eventName = eventName;
		this.checkForBackToBackDuplicates = false;
		this.wasSuccessful = wasSuccessful;
		this.products = products;
	}

	get reflektionSkuKeys(): Array<string> {
		const skuKeys: Array<string> = this.reflektion.map(({ skuKey }) => {
			return skuKey;
		});
		return skuKeys;
	}

	get trackingJson(): IAddToCartEventJSON | IAddToWishlistEventJSON {
		return {
			event: this.eventName,
			cart: {
				id: this.cart.id,
				add: this.cart.sumPriceChanged,
			},
			product: this.products.map((product) => {
				return product.trackingJson;
			}),
		};
	}

	get comparatorString(): string {
		return `[${this.products.map(product => product.comparatorString).join(',')}]`;
	}

	equalTo(comparatorString: string): boolean {
		return this.comparatorString === comparatorString;
	}
}
