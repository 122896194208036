import {
	makeObservable, observable, action,
} from 'mobx';

import { TrackedProduct } from '~/product/common/Models/TrackedProduct.tracking';

import { LAUNCH_PLACEHOLDER_TEXT } from '~/util/launchUtils';

export class TrackedCart {
	products: Array<TrackedProduct>

	addToCartData: any

	constructor(addToCartData: any, products: Array<TrackedProduct>) {
		makeObservable(this, {
			addToCartData: observable.ref,
			equalTo: action.bound,
		});

		this.addToCartData = addToCartData;
		this.products = products;
	}

	get id(): string {
		const {
			cart: {
				analytics: {
					cartNumber = null,
				} = {},
			} = {},
			analytics: {
				cartNumber: backupCartNumber = null,
			} = {},
		} = this.addToCartData;
		return cartNumber || backupCartNumber || LAUNCH_PLACEHOLDER_TEXT;
	}

	get sumPriceChanged(): number {
		return this.products.reduce((sum, { unitPrice, quantity }) => sum + unitPrice * quantity, 0);
	}

	get wasNewCartCreated(): boolean {
		const {
			cart: {
				analytics: {
					adobeLaunchCartCreationEvent = false,
				} = {},
			} = {},
			analytics: {
				adobeLaunchCartCreationEvent: engageAdobeLaunchCartCreationEvent = false,
			} = {},
		} = this.addToCartData;

		return adobeLaunchCartCreationEvent || engageAdobeLaunchCartCreationEvent;
	}

	get cancelNewCartHref(): string | undefined {
		const {
			cart: {
				analytics: {
					_links: {
						adobeLaunchCartCreationEvent: {
							href = undefined,
						} = {},
					} = {},
				} = {},
			} = {},
			analytics: {
				_links: {
					adobeLaunchCartCreationEvent: {
						href: engageShopHref = undefined,
					} = {},
				} = {},
			} = {},
		} = this.addToCartData;

		return href || engageShopHref;
	}

	get comparatorString(): string {
		return this.id;
	}

	equalTo(comparatorString: string): boolean {
		return this.comparatorString === comparatorString;
	}
}
