import { computed, observable, makeObservable } from 'mobx';

import { formModel, formSettings } from '~/account/create/Models/createAccount.model';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';

export class CreateAccountStore {
	constructor(callback, defaults) {
		makeObservable(this, {
			form: observable.ref,
			isSubmitProcessing: observable,
			isSubmitted: observable,
			hasRequestError: observable,
			callback: observable,
			isInModal: computed,
			useConvertGuest: computed,
			convertGuestLink: computed,
		});

		this.callback = callback;
		this.defaults = defaults;
	}

	form

	isSubmitProcessing = false

	isSubmitted = false

	hasRequestError = false

	callback

	get isInModal() {
		return Boolean(this.defaults?.isInModal);
	}

	get useConvertGuest() {
		return Boolean(this.defaults?.useConvertGuest);
	}

	get convertGuestLink() {
		return this.defaults?.convertGuestLink;
	}
}

export const CreateAccountStoreFactory = {
	create(callback, defaults, globalDynamicStore, HREF) {
		const store = new CreateAccountStore(callback, defaults);

		store.form = new FormBuilder(formModel(defaults), formSettings(store, globalDynamicStore, HREF));
		return store;
	},
};
