import { IAddToCartEventJSON } from '~/product/common/Interfaces/IAddToCart.tracking';
import { AddProductToCartTrackingBase } from '~/product/common/Models/AddProductToCartBase.tracking';
import { TrackedCart } from '~/product/common/Models/TrackedCart.tracking.model';
import { TrackedProduct } from '~/product/common/Models/TrackedProduct.tracking';

export class AddProductToCartEvent extends AddProductToCartTrackingBase {
	addToCartTrackingBase: any

	checkForBackToBackDuplicates

	constructor(addToCartData: any = {}, products: Array<TrackedProduct> = [], cart: TrackedCart, reflektion: Array<any> = [], variantIds: Array<string> = []) {
		super(addToCartData, products, cart, reflektion, variantIds, 'addcart', true);
		this.checkForBackToBackDuplicates = false;
	}

	get trackingJson(): IAddToCartEventJSON {
		return {
			event: this.eventName,
			cart: {
				id: this.cart.id,
				add: this.cart.sumPriceChanged,
			},
			product: this.products.map((product) => {
				return product.trackingJson;
			}),
			reflektionSkuKeys: this.reflektionSkuKeys,
			variantIds: this.variantIds,
			vendor: {
				reflektion: this.reflektion,
				variantIds: this.variantIds,
			},
		};
	}
}
